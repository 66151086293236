.main-banner {
    position: relative;
    z-index: 99;
    min-height: 100vh;
    padding-bottom: 92px;
    padding-top: 131px;

    .banner-bg {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 137px;
        z-index: -1;
    }

    .banner-content {
        max-width: 1026px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        border: 1px solid #2F2828;
        background: rgba(0, 0, 0, 0.95);
        backdrop-filter: blur(5px);
        padding: 40px;
        position: relative;
        gap: 40px;

        .buy-card-right-bottom {
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: -1;
            pointer-events: none;
        }

        .left-side-content {
            max-width: 420px;
            width: 100%;
        }


        .new-style-box {
            position: relative;
            z-index: 9;
            overflow: hidden;
            flex: 1;
            border: 1px solid #2F2828;
            background: rgba(0, 0, 0, 0.70);
            backdrop-filter: blur(5px);
            padding: 25px;



            .buy-card-bg {
                position: absolute;
                top: 0;
                left: 0;
                object-fit: cover;
                z-index: -1;
                pointer-events: none;
                height: 100%;
            }
        }

        .timermain {
            display: flex;
            width: 100%;
            padding: 20px 30px;
            justify-content: center;
            align-items: center;
            margin-bottom: 25px;

            .timerleft {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 30px;

                .mainiconimg {
                    width: 55px;
                    height: 55px;
                    border-radius: 120px;
                    object-fit: cover;
                    object-position: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .iconimginner {
                        width: 100%;
                        height: 100%;
                        border-radius: 120px;
                        object-fit: cover;
                        object-position: center;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }

                .timerhead {
                    color: #fff;
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 30px;
                }

                .timerpara {
                    color: #9292C1;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 30px;
                }
            }

            .timerended {
                color: #fff;
                text-align: center;
                font-size: 22px;
                font-style: normal;
                font-weight: 700;
                line-height: 30px;
            }

            .timerspans {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;

                .maintime {
                    width: 54.5px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    gap: 5px;

                    .innertimer {
                        background: linear-gradient(0deg, #FFF 0%, #FFF 100%), #000;
                        display: flex;
                        padding: 11px 5px;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        width: 40px;
                        height: 40px;

                        .innertimertext {
                            color: #000;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 110%;
                        }
                    }

                    .innertimepara {
                        color: #fff;
                        text-align: center;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 110%;
                    }
                }
            }
        }

        .upper-content {
            background: rgba(255, 255, 255, 0.12);
            display: flex;
            justify-content: space-between;
            align-items: center;
            ;
            padding: 20px 30px;
            margin-bottom: 20px;

            .left {
                display: flex;
                align-items: center;
                gap: 11px;

                .text {
                    h6 {
                        color: #fff;
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 100%;
                        letter-spacing: 0.1px;
                        margin-bottom: 5px;
                    }

                    p {
                        color: #A5A5A7;
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 100%;
                        letter-spacing: 0.1px;
                    }
                }
            }

            .right {
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;

                h6 {
                    color: #fff;
                    font-size: 24px;
                    font-weight: 700;
                    line-height: 100%;
                    letter-spacing: 0.1px;
                    margin-bottom: 8px;
                    text-align: end;
                }

                p {
                    color: #28E664;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 100%;
                    letter-spacing: 0.1px;
                    text-align: end;
                }

                .red {
                    color: #f41e5e;
                }
            }
        }

        .bottom-content {


            .twice-data {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .dropdown {

                    .dropdown-toggle {
                        border: 1px solid #2F2828;
                        background: #000;
                        padding: 9px;
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        color: #fff !important;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 100%;
                        letter-spacing: 0.1px;
                        img{
                            width: 18px;
                            height: 18px;
                        }

                        h6{
                            color: #FFF;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 100%;
                            letter-spacing: 0.1px;
                        }

                        &::after {
                            display: none;
                        }

                        &[aria-expanded="true"] {
                            border: 1px solid #2DD98F;
                            background: #000;

                            svg {
                                transform: rotate(180deg);
                            }
                        }

                        // &::after {
                        //     display: none;
                        // }
                    }

                    .dropdown-menu {
                        border: 1px solid #1B1B1B;
                        background: #141414;
                        padding: 8px;
                        width: 100%;
                        overflow: hidden;
                        left: unset !important;
                        right: 0 !important;
                        border-radius: 0;
                        margin-top: 4px;

                        a {
                            color: #fff;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 100%;
                            letter-spacing: 0.1px;
                            padding: 14px 12px;
                            border: 1px solid #1B1B1B;
                            background: #141414;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            margin-bottom: 5px;
                           

                            &:hover {
                                border: 1px solid #1B1B1B;
                                background: #1D1D1D !important;
                            }

                            img {
                                width: 18px;
                                height: 18px;
                                margin-right: 10px;

                            }
                        }
                    }
                }

            }

            .main-head {
                color: #fff;
                text-align: start;
                font-size: 20px;
                font-weight: 700;
                line-height: 30px;

            }

            .parent-box {
                position: relative;

                .bottom-select-amount {
                    margin: 25px 0;

                    h6 {
                        color: #FFF;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 110%;
                    }

                    .amount-div {
                        border: 1px solid #282828;
                        background: #000001;
                        padding: 12px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-top: 20px;
                        height: 56px;

                        .minus-btn {
                            border: 1px solid #282828;
                            background: #000001;
                            box-shadow: 0px -5px 0px 0px rgba(0, 0, 0, 0.02) inset;
                            width: 32px;
                            height: 32px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: #8B8B8B;
                            font-size: 30px;

                            &.active {
                                color: #000;
                                background-color: #2DD98F;
                                border: none !important;
                            }
                        }

                        .plus-btn {
                            border: 1px solid #2DD98F;
                            background: #2DD98F;
                            box-shadow: 0px -5px 0px 0px rgba(0, 0, 0, 0.02) inset;
                            width: 32px;
                            height: 32px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: #000;
                            font-size: 20px;
                        }

                        span {
                            color: #FFF;
                            text-align: center;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 110%;
                        }

                    }
                }
            }

            .inner-box {
                background: rgba(255, 255, 255, 0.12);
                padding: 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 10px;

                .leftiii {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    max-width: 102px;
                    width: 100%;
                    padding: 10px;
                    border: 1px solid #2DD98F;

                    h6 {
                        color: #fff;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 100%;
                        letter-spacing: 0.1px;
                    }
                }

                .left {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    max-width: 110px;
                    width: 100%;



                    .text {
                        h6 {
                            color: #fff;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 100%;
                            letter-spacing: 0.1px;
                        }
                    }
                }

                .right {
                    display: flex;
                    justify-content: flex-end;
                    align-items: flex-end;
                    flex-direction: column;

                    h6 {
                        color: #fff;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 100%;
                        text-align: right;
                    }

                    // p{
                    //     color: rgba(255, 255, 255, 0.20);
                    //     font-size: 28px;
                    //     font-weight: 700;
                    //     line-height: 120%;
                    //     text-align: end;
                    // }
                    input {
                        color: #fff;
                        font-size: 28px;
                        font-style: normal;
                        font-weight: 700;
                        text-align: end;
                        background-color: transparent;
                        border: none;
                        max-width: 200px;
                        width: 100%;


                        &::placeholder {
                            color: #fff;
                            font-size: 28px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 120%;
                            text-align: end;
                        }
                    }
                }
            }

        }

        .bottom-text {
            margin-top: 25px;
            background: #ffffff1f;
            padding: 15px;


            .inner-text {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 20px;

                p {
                    color: #fff;
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }

                h6 {
                    color: #fff;
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
            }

            .inner-text-bold {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 15px 0;
                border-bottom: 1px solid rgba(255, 255, 255, 0.20);

                &:last-child {
                    border: none;
                }

                h6 {
                    color: #fff;
                    text-align: right;
                    font-size: 14px;
                    font-weight: 500;
                }
            }
        }

        .btn-buy {
            background: #33604C;
            display: flex;
            padding: 18px 25px;
            justify-content: center;
            align-items: center;
            color: #2B2B2B;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            text-transform: uppercase;
            width: 100%;
            border: none;
            margin-top: 22px;
        }
    }

    .twice-content {
        display: grid;
        // grid-template-columns: 1fr 1fr;
        // gap: 20px;
        margin-bottom: 20px;

        .upper-content {
            margin-bottom: 0 !important;
            padding: 16px 20px !important;
            background: rgba(255, 255, 255, 0.04);
            backdrop-filter: blur(5px);
        }

        .upper-content .right h6 {
            font-size: 20px !important;
            margin-bottom: 0 !important;
        }

        .availablemain {
            max-width: 260px;
            width: 100%;
            padding: 20px;
            border-radius: 12px;
            border: 1px solid #E6E6E6;
            background: #FFF;
            box-shadow: 0px -7px 0px 0px rgba(0, 0, 0, 0.04) inset;

            .availablehead {
                color: var(--Black, #000);
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
                letter-spacing: 0.1px;

                display: flex;
                align-items: center;
                gap: 6px;
            }

            .availablepara {
                color: #B6B6B6;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
                letter-spacing: 0.1px;
                margin-top: 8px;
                margin-bottom: 11px;

                .greynumber {
                    color: #717171;
                }
            }

            .progress {
                background: rgba(55, 55, 55, 0.20);
                height: 11px;
                border-radius: 15px;

                .progress-bar {
                    background-color: #53FFEA;
                    border-radius: 15px;
                }
            }
        }


    }

    .progress-bar-container {
        margin-top: 25px;

        .progress {
            background: rgba(255, 255, 255, 0.07);
            backdrop-filter: blur(5px);
            border-radius: 0;

            .progress-bar {
                background: #2DD98F;
            }
        }

        .inside-text {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 12px;

            p {
                color: #A5A5A7;
                text-align: center;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }

            h6 {
                color: #FFF;
                text-align: right;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }
    }

}

.newClasss {
    background: #2DD98F;
    border: none;
    display: flex;
    padding: 18px 25px;
    justify-content: center;
    align-items: center;
    color: #000;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    border: none;
    width: 100%;
    margin-top: 24px;
}

.proceedtopurchase{
    background: rgba(255, 255, 255, 0.05);
    border: none;
    display: flex;
    padding: 18px 25px;
    justify-content: center;
    align-items: center;
    color: #4D4D4D;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    border: none;
    width: 100%;
    margin-top: 24px;
    cursor: default !important;
}

.suxvsvsyucsusc {
    width: 30px;
    height: 30px;
}

.custom-tabs {
    .nav-tabs {
        margin-bottom: 20px;

        border-radius: 12px;
        border: 1px solid #E6E6E6;
        justify-content: space-between;

        .nav-item {
            flex: 1;

            .nav-link {
                width: 100%;
                padding: 20px 10px;
                border: none;
                background-color: transparent;
                color: #000;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 110%;
                border-radius: 0;
            }
        }

        .nav-item.show .nav-link,
        .nav-link.active {
            color: #fff;
            border-radius: 8px;
            background: var(--Black, #000);
            box-shadow: 0px -7px 0px 0px rgba(255, 255, 255, 0.20) inset;
        }
    }
}

.discount-offer {
    border-radius: 12px;
    background: var(--Black, #000);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    margin: 10px 0;
    position: relative;
    z-index: 9;
    overflow: hidden;

    .discont-bg {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        pointer-events: none;
        width: 100%;
        height: 100%;
    }

    .left-sidee {
        .upper-text {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            margin-bottom: 10px;

            h6 {
                color: #FFC700;
                font-size: 22px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                letter-spacing: -0.22px;
                text-transform: uppercase;
            }
        }

        p {
            color: #BED7D3;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            letter-spacing: 0.28px;
            max-width: 336px;
            width: 100%;
        }
    }

    .right-sidee {
        .btn-discount {
            border-radius: 15px;
            background: #53FFEA;
            box-shadow: 0px -5px 0px 0px rgba(0, 0, 0, 0.20) inset;
            padding: 16px 17px;
            color: var(--Black, #000);
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            text-transform: uppercase;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .see-plan {
            display: flex;
            justify-content: flex-end;
            flex-direction: column;

            .btn-seeplan {
                border-radius: 15px;
                border: 1px solid #292929;
                background: var(--Black, #000);
                box-shadow: 0px -5px 0px 0px rgba(255, 255, 255, 0.16) inset;
                padding: 16px 17px;
                color: #FFF;
                text-align: center;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
                text-transform: uppercase;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            p {
                color: #FFF;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                letter-spacing: 0.24px;
                margin-top: 5px;
            }
        }
    }

}

.inner-text-bolddddd {
    .twice-text {
        h6 {
            color: var(--Black, #000);
            text-align: center;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        p {
            color: #717171;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-top: 2px;
        }
    }
}

.border-set-custom {
    background: #E6E6E6;
    width: 100%;
    display: flex;
    height: 1px;
    margin: 20px 0;
}

@media (max-width:600px) {
    .main-banner .banner-content .timermain .timerleft .timerhead {
        font-size: 16px;
    }

    .main-banner .banner-content .timermain .timerleft .timerpara {
        font-size: 12px;
    }

    .main-banner .banner-content .timermain .timerspans .maintime .innertimer .innertimertext {
        font-size: 12px;
    }

    .main-banner .banner-content .timermain .timerspans .maintime .innertimer {
        padding: 0;
        width: 30px;
        height: 28px;
    }

    .main-banner .banner-content .timermain .timerspans .maintime .innertimepara {
        font-size: 7px;
    }

    .main-banner .banner-content .timermain .timerspans .maintime {
        width: 31px;
    }

    .main-banner .banner-content .timermain .timerspans {
        gap: 7px;
    }

    section.main-banner {
        padding-bottom: 90px;
    }

    .main-banner .banner-content .upper-content .right h6 {
        font-size: 14px;
    }

    .main-banner .twice-content {
        grid-template-columns: auto
    }

    .main-banner .twice-content .availablemain {
        max-width: 100%;
    }

    .main-banner .banner-content .bottom-text .inner-text-bold h6 {
        text-align: unset !important;
        font-size: 14px;
    }

    .main-banner .banner-content .timermain {
        padding: 20px;
    }

    .main-banner .banner-content .timermain .timerleft .timerpara,
    .main-banner .banner-content .timermain .timerspans .maintime .innertimer .innertimertext {
        line-height: 15px;
    }

    .main-banner .banner-content .timermain .timerleft {
        gap: 10px;
    }

    .main-banner .banner-content {
        flex-direction: column;
        padding: 15px;
        gap: 22px;
    }
    .main-banner .banner-content .new-style-box{
        padding: 15px;
    }
}

@media (max-width:600px) {
    .main-banner .material-textfield label {
        padding: 0 0rem 0 0.3rem !important;
    }

    .discount-offer {
        flex-direction: column;
        gap: 15px;
    }

    .discount-offer .right-sidee {
        width: 100%;
    }

    .discount-offer .right-sidee .see-plan p {
        text-align: center;
    }

    .main-banner .banner-content .bottom-content .main-head {
        font-size: 14px;
    }
}

.right-text-content {
    display: flex;
    align-items: center;
    gap: 10px;

    .inner-left {
        h5 {
            background: black;
            padding: 4px 6px;
            color: white;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            letter-spacing: 0.1px;
            text-transform: uppercase;
            margin-bottom: 6px;
        }

        .special-para {
            color: #B6B6B6 !important;
            text-align: center !important;
            font-size: 14px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: 100% !important;
            letter-spacing: 0.1px !important;
        }
    }

    .inner-right {
        p {
            color: #B6B6B6 !important;
            font-size: 16px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: 100% !important;
            letter-spacing: 0.1px !important;
            margin-top: 8px !important;
            text-decoration: line-through;
        }
    }
}


.buyjustdop {
    .dropdown .dropdown-menu a img {
        width: 18px !important;
        height: 18px !important;
        object-fit: cover;
    }
}

.error-mesg-show {
    color: #FF4A4A;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    text-align: center;
    margin-top: 8px;
}

.error-color {
    color: #FF4A4A !important;
}


.tooltip-inner {
    border-radius: 12px !important;
    border: 1px solid #242424 !important;
    background: var(--Black, #000) !important;
    box-shadow: 0px 14px 34px 0px rgba(0, 0, 0, 0.10) !important;
    padding: 17px !important;
    max-width: 303px !important;

    h5 {
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
        margin-bottom: 8px;
        text-align: start !important;
    }

    p {
        color: #CECECE;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: start !important;
    }
}



.Apppppppp {
    text-align: center;

    input[type="range"] {
        width: 100%;
        margin: 20px 0;
    }

    .gauge-container {
        width: 100%;
        margin: 20px 0;
        margin-bottom: 0;
    }

    .gauge {
        position: relative;
        width: 100%;
        height: 10px;
        background: linear-gradient(to right, red, orange, yellow, green);
        border-radius: 10px;
    }

    .gauge-indicator {
        position: absolute;
        top: 0px;
        width: 4px;
        height: 10px;
        background-color: #000;
        transition: left 0.3s ease;
    }

    .gauge-labels {
        display: flex;
        justify-content: space-between;
        margin-top: 5px;
    }

    .gauge-labels span {
        color: gray;
        font-size: 14px;
    }
}

@media (max-width:390px) {
    .right-text-content .inner-left h5 {
        white-space: nowrap;
    }

    .main-banner .banner-content .upper-content .right .inner-right {
        max-width: 80px;
    }

    .right-text-content {
        align-items: flex-start;
    }
}

.specific-color {
    color: #A5A5A7 !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
}
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

html {
  scroll-behavior: smooth;
}

body {
  background: #000;
  font-family: "Inter", sans-serif;
  position: relative;
  min-height: 100vh;
}

.bottom-center-shadow{
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: -1;
  pointer-events: none;
}


.row {
  margin: 0;
  padding: 0;
}

.p-0 {
  padding: 0px;
}

.p0 {
  padding: 0px;
}

// All transitions
div,
ul,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
button {
  -webkit-transition: background-color 0.7s ease-out;
  -moz-transition: background-color 1s ease-out;
  -o-transition: background-color 1s ease-out;
  transition: background-color 1s ease-out;
}

textarea {
  resize: none;
  height: 60px;
  width: 100%;
}

a {
  text-decoration: none !important;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

div,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0;
}


.grey {
  color: #888 !important;
}



.ptb20 {
  padding: 20px 0;
}




.ptb60 {
  padding: 60px 0;
}






.cursor-pointer {
  cursor: pointer;
}

.purple {
  color: #6c01c3;
}

.yellow {
  color: #ffc13d;
}

.border-img {
  border-radius: 10px;
}

.common {
  color: #ff0083;
}

.green {
  color: #6dff39;
}

.red {
  color: #f41e5e;
}

.white {
  color: #fff !important;
}

.blue {
  color: #133572;
}

.navbar-toggle {
  background-color: #333;

  .icon-bar {
    background-color: #eee;
  }
}


@media only screen and (max-width: 600px) {
  .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    outline: none;

    &:focus {
      outline: none;
    }
  }

  .main-footer {
    text-align: center;

    .text-right {
      text-align: center !important;
    }
  }

  h1 {
    font-size: 2.5rem !important;
  }

  .ptb {
    padding: 30px 0;
  }

  .xs-0 {
    padding: 0px;
  }

  .main-mint,
  .main-banner {
    .inner-content {
      .modal-content {
        .for-padding {
          padding: 0 0px !important;
        }
      }
    }
  }

  .main-give {
    .right-side {
      margin-top: 30px !important;

      h5 {
        font-weight: 500;
      }

      li {
        margin-right: 2px !important;
      }
    }
  }
}

// 12 pro
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {

  h1 {
    font-size: 3rem !important;
  }

  .ptb {
    padding: 20px 0;
  }
}



iframe {
  position: unset !important;
  top: 0px !important;
  left: 0px !important;
  width: 0px !important;
  height: 0px !important;
}

button {
  cursor: pointer;
}

@media (max-width: 600px) {
  h2 {
    font-size: 30px !important;
  }

  .col-md-6 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .col-md-8 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .col-md-4 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

button:focus {
  outline: none !important;
}

input:focus {
  outline: none !important;
}

.form-control:focus {
  box-shadow: unset;
}


@media(max-width:600px) {
  .modal.show .modal-dialog {
    max-width: 100% !important;
  }
}

button {
  cursor: pointer;
}

a {
  cursor: pointer;
}

button,
input,
optgroup,
select,
textarea:focus-visible {
  outline: none !important;

}

button,
input,
optgroup,
select,
textarea:focus {
  outline: none !important;

}

button.close {
  opacity: 1;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.gscrxcsx {
  &:hover {
    background-color: transparent !important;
  }
}

.noora {
  color: #fff !important;
  text-align: center !important;
  font-size: 24px !important;
  font-weight: 700 !important;
  line-height: 100% !important;
  letter-spacing: 0.1px !important;
  margin-top: 25px !important;
}

@media (max-width:600px) {
  .noora {
    margin-top: 15px;
    font-size: 16px !important;
  }
}

@media (max-width:1024px) {
  .forhideee {
    display: none !important;
  }
}

section.main-banner {
  z-index: 9 !important;
}

.material-textfield {
  position: relative;
  width: 100%;
}



.material-textfield input {
  outline: none;
  transition: 0.1s ease-out;
  border-radius: 6px;
  border: 1px solid #282828;
  background: #000E09;
  backdrop-filter: blur(10px);
  padding: 16px 12px;
  width: 100%;
  box-shadow: none !important;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;

  &::placeholder {
    color: #A6A6A6;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  }
}


@media (max-width:768px) {
  .main-banner .banner-bg {
    display: none !important;
  }

  .main-banner .mblviewbanner-bg {
    display: block !important;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 99px;
    z-index: -1;
  }

  .login-screen .mblviewbanner-bg {
    display: block !important;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 99px;
    z-index: -1;
  }

  .login-screen .banner-bg {
    display: none !important;
  }

  .login-screen .login-bg {
    display: none !important;
  }
}


.mainLoader {
  //   -webkit-filter: blur(100px);
  // -moz-filter: blur(100px);
  // -ms-filter: blur(100px);
  // -o-filter: blur(100px);
  backdrop-filter: blur(100px);
  -moz-backdrop-filter: blur(100px) !important;
}

@media (max-width:600px){
  .leftimg {
    max-width: 188.889px;
    width: 100%;
    height: 143.444px;
  }
  
  .rightimg {
    max-width: 188.889px;
    width: 100%;
    height: 143.444px;
  }
}

.paracolorset{
  color: #636363 !important;
}


.service-modal {
  background: rgba(0, 0, 0, 0.65);
  backdrop-filter: blur(4px);

  .modal-content {
    padding: 20px 20px 30px 20px;
    border-radius: 12px;
    background: #FFF;
  }

  .modal-body {
    padding: 0;
  }

  .upper-div {
    border-radius: 12px;
    border: 1px solid #E6E6E6;
    background: #FFF;
    box-shadow: 0px -7px 0px 0px rgba(0, 0, 0, 0.04) inset;

    padding: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;

    h6 {
      color: #000;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      /* 25.2px */
    }
  }

  .bottom-div {
    border-radius: 10px;
    background: #F8F8F8;
    padding: 20px;

    h6 {
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      /* 22.4px */
      margin-bottom: 10px;
    }

    p {
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      /* 19.6px */
    }
  }
}


.greybtn {
  border-radius: 15px;
  background: #48dbca;
  box-shadow: 0px -5px 0px 0px rgba(0, 0, 0, 0.2) inset;
  display: flex;
  padding: 18px 25px;
  justify-content: center;
  align-items: center;
  color: #000;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  text-transform: uppercase;
  border: none;
  outline: none;
}
.modal-backdrop{
  background: rgba(0, 0, 0, 0.65);
  backdrop-filter: blur(4px);
  opacity: 1 !important;
}

 .border-bottom-line {
  background: linear-gradient(90deg, #000 20%, #5c5c5c 50%, #000 80%);
  bottom: 0;
  height: 1px;
  left: 50%;
  position: absolute;
  transform: translate(-50%);
  width: 100%;
}

.buycomponent-bgremoved{
  .bottom-center-shadow{
    display: none !important;
  }
}